import React from 'react';
import svgLogo from './logo.png';
import {useNavigate} from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate()
    function goToHome() {
        navigate("/");
    }
    return (
        <header className="header">
            <div className="header__container container">
                <a href="#" onClick={goToHome}>
                    <img src={svgLogo} alt="logo" className="header__logo"/>
                </a>
                <p className="paragraph-reset header__title">
                    Интеграционная платформа для оценки жизненного цикла водородных технологий
                </p>
                <a href="mailto:tuchin00@bk.ru" className="btn header__btn">
                    Связаться с нами
                </a>
            </div>
        </header>
    );
};

export default Navbar;