import React, {forwardRef, useImperativeHandle, useState} from "react";
import electroliz from "./schema_elektroliz.svg";
import CalculateService from "../../../API/CalculateService";
import SecondInputs from "./Inputs";

const Second = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        validate() {
            let arr = []
            let validateIns = validatePositiveIn(ins)
            let validateOut = validatePositiveOut(out)
            if (!(validateIns && validateOut)) {
                arr.push("Убедитесь что все введенныe значения являются положительными")
            }
            return arr
        },
        calculate() {
            let res = 0
            let sumIn = 0
            for (const [key, inObject] of Object.entries(ins)) {
                for (const [key, value] of Object.entries(inObject.values)) {
                    sumIn += value * inObject.ratio
                }
            }
            let sumOut = Number(out)

            res = sumIn / sumOut

            return res.toFixed(2) + "  СО\u2082экв/кгН\u2082"
        },
    }));
    const calc = CalculateService.getSecondCalc()
    const [ins, setIns] = useState(calc.ins)
    const [out, setOut] = useState(calc.out)

    const validatePositiveIn = (ins) => {
        for (const [key, inObject] of Object.entries(ins)) {
            for (const [key, value] of Object.entries(inObject.values)) {
                console.log("!!!!!!!!!!", value, Number(value) < 0)
                if (Number(value) < 0) {
                    return false
                }
            }
        }
        return true
    }
    const validatePositiveOut = (out) => {
        return Number(out) >= 0;

    }
    return (
        <div className="elektroliz">
            <img className="elektroliz__img" src={electroliz} alt="schema"/>
            <SecondInputs ins={ins} setIns={setIns} out={out} setOut={setOut}></SecondInputs>
        </div>
    )
});

export default Second;