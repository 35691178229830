import React, {useState} from "react";

const ThirdInputs = ({ins, setIns, out, setOut}) => {
    let arr = new Array(3)
    let [arrColor, setArrColor] = useState(Array(3).fill("#1A1A19"))
    const validateField = (i, tr) => {
        if (Number(tr) < 0 && tr !== "") {
            arrColor[i] = "#FD002E"
        } else {
            arrColor[i] = "#1A1A19"
        }
        setArrColor(arrColor)
    }
    return (
        <>
            <input value={arr[0]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[0] = e.target.value
                validateField(0, arr[0])
                newIns.in1.values["0"] = arr[0]
                setIns(newIns)
            }} className="schema-input transportirovka__input_1" type="number" name="" id="input_1" style={{color: arrColor[0]}}/>
            <input value={arr[1]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[1] = e.target.value
                validateField(1, arr[1])
                newIns.in2.values["0"] = arr[1]
                setIns(newIns)
            }} className="schema-input transportirovka__input_2" type="number" name="" id="input_2" style={{color: arrColor[1]}}/>
            <input value={arr[2]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[2] = e.target.value
                validateField(2, arr[2])
                newIns.in3.values["0"] = arr[2]
                setIns(newIns)
            }} className="schema-input transportirovka__input_3" type="number" name="" id="input_3" style={{color: arrColor[2]}}/>
        </>

    );
}

export default ThirdInputs;