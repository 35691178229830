import React from "react";
import banner from "./banner.png";
import {useNavigate} from "react-router-dom";

const Welcome = () => {
    const navigate = useNavigate()
    function goToFirst() {
        navigate("/settlements");
    }
    return (
        <main className="main">
            <div className="main__container container">
                <div className="hero">
                    <div className="hero__banner">
                        <img src={banner} alt="banner"/>
                    </div>
                    <div className="hero__text text">
                        <div className="text__1">
                            <h1 className="head-reset text__head">
                                О платформе
                            </h1>
                            <p className="paragraph-reset text__descr">
                                Калькулятор рассчитывает углеродный след водорода, полученного, а также
                                транспортируемого
                                несколькими путями.
                            </p>
                            <p className="paragraph-reset text__descr">
                                Система разработана на основе ведущих методологий расчета выбросов ПГ.
                            </p>
                        </div>
                        <hr className="separator"/>
                        <div className="text__2">
                            <h1 className="head-reset text__head">
                                Как пользоваться
                            </h1>
                            <p className="paragraph-reset text__descr">
                                Для расчета необходимо провести инвентаризацию и числено описать все учитываемые потоки
                                энергии и вещества.
                            </p>
                            <p className="paragraph-reset text__descr">
                                Калькулятор посчитает углеродный след вашего водорода и выдаст соответствующий
                                сертификат.
                            </p>
                        </div>
                        <hr className="separator"/>
                        <div className="text__3">
                            <h1 className="head-reset text__head">
                                Легко начать
                            </h1>
                            <p className="paragraph-reset text__descr text__descr_last">
                                Вы можете легко воспользоваться платформой прямо сейчас и определить углеродный след
                                вашего
                                водорода!
                            </p>
                        </div>
                        <a href="" onClick={goToFirst} className="btn text__btn">
                            Начать расчет
                        </a>
                        <br/>
                    </div>

                </div>
            </div>
        </main>
    );
};

export default Welcome;