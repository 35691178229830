import gas from "./schema_gas.svg";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import CalculateService from "../../../API/CalculateService";
import { Tooltip } from 'react-tooltip';
import Table from "../table/Table";
import ListTooltips from "./Tooltips";
import Input from "../input/Input";
import FirstInputs from "./Inputs";

const FirstNew = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        validate() {
            let arr = []

            let validateTable1 = validatePositive(firstTable.components)
            let validateTable2 = validatePositive(secondTable.components)
            let validateIn1 = validatePositiveIn(ins.in1.values)
            let validateIn2 = validatePositiveIn(ins.in2.values)
            let validateIn3 = validatePositiveIn(ins.in3.values)
            let validateIn4 = validatePositiveIn(ins.in4.values)
            let validateOut = validatePositiveOut(out)

            console.log("validate", validateTable1 , validateTable2 , validateIn1 , validateIn2 , validateIn3 , validateIn4)

            if (!(validateTable1 && validateTable2 && validateIn1 && validateIn2 && validateIn3 && validateIn4 && validateOut)) {
                arr.push("Убедитесь что все введенныe значения являются положительными")
            }

            let sum1 = sumComponents(firstTable.components)
            let sum2 = sumComponents(secondTable.components)

            if (sum1 > firstTable.maxSum) {
                arr.push("Энергетический микс не может быть больше " + firstTable.maxSum + "%")
            }
            if (sum1 < firstTable.minSum) {
                arr.push("Энергетический микс не может быть меньше " + firstTable.minSum + "%")
            }
            if (sum2 > secondTable.maxSum) {
                arr.push("Состав сжигаемого газа не может быть меньше " + secondTable.maxSum + "%")
            }
            if (sum2 < secondTable.minSum) {
                arr.push("Состав сжигаемого газа не может быть меньше " + secondTable.minSum + "%")
            }
            return arr
        },
        calculate() {
            console.log("calc")
            let ef1 = ins.in1.ratio
            let ef2 = ins.in2.ratio
            let ef3 = efComponents(firstTable.components) * 0.01
            let ef4 = efComponents(secondTable.components) * 0.01 * 1.8393

            console.log("ef", ef1, ef2, ef3, ef4)

            let sumIn1 = sumInputs(ins.in1.values)
            let sumIn2 =  sumInputs(ins.in2.values)
            let sumIn3 =  sumInputs(ins.in3.values)
            let sumIn4 =  sumInputs(ins.in4.values)

            console.log("in", sumIn1, sumIn2, sumIn3, sumIn4)

            let sumOut = Number(out)

            console.log("out", sumOut)

            let res = ((ef1 * sumIn1) + (ef2 * sumIn2) + (ef3 * sumIn3) + (ef4 * sumIn4)) / sumOut

            console.log(res)

            return res.toFixed(2) + "  СО\u2082экв/кгН\u2082"
        },
    }));
    const calc = CalculateService.getFirstCalc()
    const [firstTable, setFirstTable] = useState(calc.tables.first);

    const [secondTable, setSecondTable] = useState(calc.tables.second);

    const [ins, setIns] = useState(calc.ins)

    const [out, setOut] = useState(calc.out)

    const sumInputs= (inss) => {
        let sum = 0
        for (const [key, value] of Object.entries(inss)) {
            sum += Number(value)
        }
        return sum
    }

    const sumComponents = (components) => {
        let sum = 0
        for (const [key, value] of Object.entries(components)) {
            sum += Number(value.value)
        }
        return sum
    }

    const efComponents = (components) => {
        let ef = 0
        for (const [key, value] of Object.entries(components)) {
            ef += Number(value.value) * value.ratio
        }
        return ef
    }

    const validatePositive = (components) => {
        console.log(components)
        for (const [key, value] of Object.entries(components)) {
            console.log(Number(value.value))
            if (Number(value.value) < 0) {
                return false
            }
        }
        return true
    }

    const validatePositiveIn = (components) => {
        for (const [key, value] of Object.entries(components)) {
            if (Number(value) < 0) {
                return false
            }
        }
        return true
    }

    const validatePositiveOut = (out) => {
        return Number(out) >= 0;

    }

    return (
        <div className="gas">
            <img className="gas__img" src={gas} alt="schema"/>
            <Table className="table-wrapper_1" classNameSecond="gas__table_1 table1" table={firstTable} needTooltip={false}
                   setTable={setFirstTable}></Table>
            <Table className="table-wrapper_2" classNameSecond="gas__table_2 table2" table={secondTable} needTooltip={true}
                   setTable={setSecondTable}></Table>
            <FirstInputs ins={ins} setIns={setIns} out={out} setOut={setOut}></FirstInputs>
            <ListTooltips></ListTooltips>
        </div>
    )
});

export default FirstNew;