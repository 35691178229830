import React, {forwardRef, useImperativeHandle, useState} from "react";
import transpartirovka from "./schema_transportirovka.svg";
import CalculateService from "../../../API/CalculateService";
import ThirdInputs from "./Inputs";
import ThirdListTooltips from "./Tooltips";

const Third = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        validate() {
            let arr = []
            let validateIns = validatePositiveIn(ins)
            if (!(validateIns)) {
                arr.push("Убедитесь что все введенныe значения являются положительными")
            }
            return arr
        },
        calculate() {
            let res = 0
            let sumIn = 0
            for (const [key, inObject] of Object.entries(ins)) {
                for (const [key, value] of Object.entries(inObject.values)) {
                    sumIn += value * inObject.ratio
                }
            }
            res = sumIn

            return res.toFixed(2) + "  СО\u2082экв/кгН\u2082"
        },
    }));
    const calc = CalculateService.getThirdCalc()
    const [ins, setIns] = useState(calc.ins)

    const validatePositiveIn = (ins) => {
        for (const [key, inObject] of Object.entries(ins)) {
            for (const [key, value] of Object.entries(inObject.values)) {
                console.log("!!!!!!!!!!", value, Number(value) < 0)
                if (Number(value) < 0) {
                    return false
                }
            }
        }
        return true
    }
    return (
        <div className="transportirovka">
            <img className="transportirovka__img" src={transpartirovka} alt="schema"/>
            <ThirdInputs ins={ins} setIns={setIns}></ThirdInputs>
            <ThirdListTooltips></ThirdListTooltips>
        </div>
    )
});

export default Third;