import {Tooltip} from "react-tooltip";
import React from "react";

const FirstListTooltips = () => {
    const tooltips = [
        "",
        "Подача природного газа в компрессор",
        "Сжатие исходного природного газа до 34.6 атм.",
        "Сжатый природный газ поступает на подогрев в теплообменник (конвекционная часть печи) для превращения тяжелых углеводородов в метан",
        "За счет тепла отработанных дымовых газов природных газ нагревается до температуры 371 °С",
        "Очистка природного газа путем сорбции сероводорода",
        "Очищенный природный газ смешивается с перегретым паром и поступает в блок парогазовой конверсии",
        "При температуре 845 °С происходит конверсия СН\u2084 на СО и Н\u2082 в среде никелевого катализатора",
        "Смесь газов после блока парогазовой конверсии поступает в теплообменник для передачи тепла и нагрева воды с последующим образованием пара",
        "Получение дополнительного количество водорода, эквивалентного содержанию СО",
        "Отделение влаги и охлаждение до 60 градусов водорода с небольшим количеством примесей (СО, СО\u2082, СН\u2084 непрореагир)",
        "Очищение водорода до 99.99% на блоке короткоцикловой адсорбции",
        "Получаемый после стадии регенерации сорбента в блоке КЦА обдувочный газ поступает в печь блока парогазовой конверсии в качестве топлива",
        ]
    const getTooltips = () => {
        const rows = [];
        for (let i = 1; i < 13; i++) {
            rows.push(
                <>
                    <div className={"schema-div gas__div_" + i} id={"tooltip_" + i} key={"tooltip_anchor_"+i}></div>
                    <Tooltip
                        key={"tooltip_"+i}
                        // Don't forget the `#`!
                        anchorSelect={"#tooltip_" + i}
                        content={tooltips[i]}
                        style={{maxWidth: "50%"}}
                    />
                </>
            )
        }
        return rows;
    }
    return getTooltips()
}

export default FirstListTooltips;