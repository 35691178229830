import React from "react";
import {Tooltip} from "react-tooltip";

const Table = ({className, classNameSecond, table, setTable, needTooltip}) => {
    const getTableRows = () => {
        const rows = [];
        for (let i = 0; i < table.components.length; i++) {
            rows.push(
                <tr key={table.components[i].name}>
                    <td className="table__left" id={table.components[i].name}>
                        {table.components[i].rusName}
                    </td>
                    <td className="table__right">
                        <input value={table.components[i].value} onChange={e => {
                            let newTable = Object.assign({}, table)
                            newTable.components[i].value = e.target.value
                            setTable(newTable)
                        }} className="table__input" type="number"/>
                    </td>
                </tr>
            );
        }
        return rows
    }

    const getTableTooltips = () => {
        const rows = [];
        for (let i = 0; i < table.components.length; i++) {
            rows.push(
                <Tooltip
                    // Don't forget the `#`!
                    anchorSelect={"#"+table.components[i].name}
                    content={table.components[i].tooltip}
                />
            );
        }
        return rows
    }

    const getTableSum = () => {
        let sum = 0;
        for (let i = 0; i < table.components.length; i++) {
            sum += Number(table.components[i].value )
        }
        return sum
    }

    const tableSum = getTableSum()

    const getResColor = () => {
        if (tableSum === 100) {
            return "#1A1A19"
        } else {
            return "#FD002E"
        }
    }
    return (
        <div className={className}>
            <table className={classNameSecond} border="1" cellPadding="4">
                <thead>
                <tr key={table.name}>
                    <th className="table__left">
                        {table.rusName}
                    </th>
                    <th className="table__right">
                        {table.unit}
                    </th>
                </tr>
                </thead>
                <tbody>
                {getTableRows()}
                <tr>
                    <th className="table__left" align="left">
                        <font color={getResColor()}>Сумарный процент:</font>
                    </th>
                    <th className="table__right" align="left">
                        <font color={getResColor()}> {getTableSum()}</font>
                    </th>
                </tr>
                </tbody>
            </table>
            {needTooltip ? getTableTooltips() : null}
        </div>
    );
};

export default Table;