import React, {useState} from 'react';
import gas from './gas.svg';
import Popup from "reactjs-popup";
import Input from "../input/Input";

const First = () => {

    const [firstTable, setFirstTable] = useState([]);

    const handleInputFirstTable = (e) => {
        const { name, value } = e.target;
        setFirstTable({
            ...firstTable,
            [name]: {value: value},
        });
    };
    const [secondTable, setSecondTable] = useState({});
    const handleInputSecondTable = (e) => {
        const { name, value } = e.target;
        setSecondTable({
            ...secondTable,
            [name]: {value: value},
        });
    };

    const [in1, setIn1] = useState({});

    const handleIn1 = (e) => {
        const { name, value } = e.target;
        setIn1({
            ...in1,
            [name]: {value: value},
        });
    };

    const [in2, setIn2] = useState({});

    const handleIn2 = (e) => {
        const { name, value } = e.target;
        setIn2({
            ...in2,
            [name]: {value: value},
        });
    };

    const [in3, setIn3] = useState({});

    const handleIn3 = (e) => {
        const { name, value } = e.target;
        setIn3({
            ...in3,
            [name]: {value: value},
        });
    };

    const [in4, setIn4] = useState({});

    const handleIn4 = (e) => {
        const { name, value } = e.target;
        setIn4({
            ...in4,
            [name]: {value: value},
        });
    };

    const [out, setOut] = useState({});

    const handleOut = (e) => {
        const { name, value } = e.target;
        setOut({
            ...out,
            [name]: {value: value},
        });
    };



    function calculateTable1() {
        let res = 0
        for (const [key, value] of Object.entries(firstTable)) {
            res += value.value * 0.1
        }
        return res
    }

    function calculateTable2() {
        let res = 0
        for (const [key, value] of Object.entries(secondTable)) {
            res += value.value * 0.1
        }
        return res
    }

    function sumIn(curIn) {
        let res = 0
        for (const [key, value] of Object.entries(curIn)) {
            res += Number(value.value)
        }
        return res
    }

    function calculateAll() {
        let ef1 = 0.1
        let ef2 = 0.1
        let ef3 = calculateTable1() * 0.01
        let ef4 = calculateTable2() * 0.01
        console.log("ef", ef1, ef2, ef3, ef4)

        let sumIn1 = sumIn(in1)
        let sumIn2 = sumIn(in2)
        let sumIn3 = sumIn(in3)
        let sumIn4 = sumIn(in4)

        console.log("in", sumIn1, sumIn2, sumIn3, sumIn4)

        let sumOut = sumIn(out)

        console.log("out", sumOut)

        let res = ((ef1 * sumIn1) + (ef2 * sumIn2) + (ef3 * sumIn3) + (ef4 * sumIn4)) / sumOut

        console.log(res)

        return (parseInt(res * 100)) / 100


    }
    const getFirstTable = (start, end) => {
        const arr = []
        for (let i = start; i <= end; i++) {
            arr.push(<Input className={"gas__table_" + i} elements={firstTable} setElements={handleInputFirstTable}
                            id={i}></Input>)
        }
        return arr
    }

    const getSecondTable = (start, end) => {
        const arr = []
        for (let i = start; i <= end; i++) {
            arr.push(<Input className={"gas__table_" + i} elements={secondTable} setElements={handleInputSecondTable}
                            id={i}></Input>)
        }
        return arr
    }

    const getIn1 = () => {
        const arr = []
        arr.push(<Input className={"gas__input_4"} elements={in1} setElements={handleIn1}
                        id={4}></Input>)
        return arr
    }

    const getIn2 = () => {
        const arr = []
        arr.push(<Input className={"gas__input_8"} elements={in2} setElements={handleIn2}
                        id={8}></Input>)
        return arr
    }

    const getIn3 = () => {
        const arr = []
        arr.push(<Input className={"gas__input_3"} elements={in3} setElements={handleIn3}
                        id={3}></Input>)
        arr.push(<Input className={"gas__input_5"} elements={in3} setElements={handleIn3}
                        id={5}></Input>)
        arr.push(<Input className={"gas__input_6"} elements={in3} setElements={handleIn3}
                        id={6}></Input>)
        arr.push(<Input className={"gas__input_7"} elements={in3} setElements={handleIn3}
                        id={7}></Input>)
        return arr
    }

    const getIn4 = () => {
        const arr = []
        arr.push(<Input className={"gas__input_2"} elements={in4} setElements={handleIn4}
                        id={2}></Input>)
        return arr
    }

    const getOut = () => {
        const arr = []
        arr.push(<Input className={"gas__input_1"} elements={out} setElements={handleOut}
                        id={1}></Input>)
        return arr
    }

    return (
        <main className="main">
            <div className="main__container container">
                <div className="menu">
                    <h3 className="head-reset menu__head">Категории расчетов</h3>
                    <ul className="list-reset menu__nav">
                        <li className="menu__item">
                            <button className="btn menu__btn menu__btn_active menu__btn_first" data-path="one">
                                Паровая конверсия природного газа
                            </button>
                        </li>
                        <li className="menu__item">
                            <button className="btn menu__btn menu__btn_last" data-path="two">
                                Электролиз
                            </button>
                        </li>
                        <li></li>
                    </ul>

                </div>


                <div class="content">
                    <div class="content__wrapper content__wrapper_active" data-target="one">

                        <div class="gas">
                            <img class="gas__img" src={gas} alt="schema"/>
                            {getFirstTable(1, 6)}


                            {getSecondTable(7, 11)}

                            {getIn1()}

                            {getIn2()}

                            {getIn3()}

                            {getIn4()}

                            {getOut()}
                        </div>

                    </div>

                    <div class="content__wrapper" data-target="two">
                        <h2 class="head-reset content__head">Электролиз</h2>

                    </div>
                </div>

            </div>

        </main>

    );
};

export default First;