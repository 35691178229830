export default class CalculateService {
    static getFirstCalc() {
        try {
            return {
                tables: {
                    first: {
                        name: "energy_mix",
                        rusName: "Энергетический микс",
                        unit: "%",
                        minSum: 100,
                        maxSum: 100,
                        components: [
                            {
                                name: "gas",
                                rusName: "Газ",
                                ratio: 0.480339,
                                value: 0
                            },
                            {
                                name: "coil",
                                rusName: "Уголь",
                                ratio: 0.833796,
                                value: 0
                            },
                            {
                                name: "diesel",
                                rusName: "Дизель",
                                ratio: 0.655557,
                                value: 0
                            },
                            {
                                name: "hydro",
                                rusName: "Гидро",
                                ratio: 0,
                                value: 0
                            },
                            {
                                name: "wind",
                                rusName: "Ветер",
                                ratio: 0,
                                value: 0
                            },
                            {
                                name: "sun",
                                rusName: "Солнце",
                                ratio: 0,
                                value: 0
                            },
                        ]
                    },
                    second: {
                        name: "gas_composition",
                        rusName: "Состав сжигаемого газа",
                        unit: "%",
                        minSum: 100,
                        maxSum: 100,
                        components: [
                            {
                                name: "metan",
                                rusName: "Метан",
                                ratio: 1,
                                value: 0,
                                tooltip: "CH4"
                            },
                            {
                                name: "etan",
                                rusName: "Этан",
                                ratio: 2,
                                value: 0,
                                tooltip: "C2H6"
                            },
                            {
                                name: "propan",
                                rusName: "Пропан",
                                ratio: 3,
                                value: 0,
                                tooltip: "C3H8"
                            },
                            {
                                name: "butan",
                                rusName: "Бутан",
                                ratio: 4,
                                value: 0,
                                tooltip: "C4H10"
                            },
                            {
                                name: "pentan",
                                rusName: "Пентан",
                                ratio: 5,
                                value: 0,
                                tooltip: "C5H12"
                            },
                            {
                                name: "other",
                                rusName: "Другое",
                                ratio: 0,
                                value: 0,
                                tooltip: "Не содержащие С"
                            },
                        ]
                    }},
                ins: {
                    in1: {
                        ratio: 0.08510834643,
                        values: {}
                    },
                    in2: {
                        ratio: 0.132,
                        values: {}
                    },
                    in3: {
                        ratio: 0,
                        values: {}
                    },
                    in4: {
                        ratio: 0,
                        values: {}
                    }
                },
                out: ""
            }
        } catch (e) {
            console.log(e)
        }
    }

    static getSecondCalc() {
        try {
            return {
                ins: {
                    in1: {
                        ratio: 0.833796,
                        values: {}
                    },
                    in2: {
                        ratio: 0.480339,
                        values: {}
                    },
                    in3: {
                        ratio: 0,
                        values: {}
                    },
                    in4: {
                        ratio: 0,
                        values: {}
                    },
                    in5: {
                        ratio: 0,
                        values: {}
                    },
                    in6: {
                        ratio: 0,
                        values: {}
                    },
                    in7: {
                        ratio: 0.655557,
                        values: {}
                    }
                },
                out: ""
            }
        } catch (e) {
            console.log(e)
        }
    }

    static getThirdCalc() {
        try {
            return {
                ins: {
                    in1: {
                        ratio: 1.7813,
                        values: {}
                    },
                    in2: {
                        ratio: 1.1,
                        values: {}
                    },
                    in3: {
                        ratio: 1.5,
                        values: {}
                    }
                },
                out: ""
            }
        } catch (e) {
            console.log(e)
        }
    }
}