import React, {useRef, useState} from "react";
import ModalPositive from "../UI/modal/Positive";
import ModalNegative from "../UI/modal/Negative";
import Popup from "reactjs-popup";
import FirstNew from "../UI/first/FirstNew";
import Second from "../UI/Second/Second";
import {Modal} from 'flowbite-react';
import Third from "../UI/third/Third";

const Settlements = () => {
    const childRef = useRef(null);

    const [modalState, setModalState] = useState({
        show: false,
        state: "positive",
        stateMsg: [""]
    })

    const openModal = () => {
        let errors = childRef.current.validate();
        if (errors.length !== 0) {
            setModalState({
                show: true,
                state: "negative",
                stateMsg: errors
            })
        } else {
            let res = childRef.current.calculate();

            console.log("11111", res.toString())
            setModalState({
                show: true,
                state: "positive",
                stateMsg: [res.toString()]
            })
            console.log(modalState)
        }
    }
    const closeModal = () => {setModalState({ ...modalState, show: false })}

    const [categoryActive, setCategoryActive] = useState({
        first: true,
        second: false,
        third: false
    })


    const setFirstActive = () => {setCategoryActive({
        first: true,
        second: false,
        third: false
    })}
    const setSecondActive = () => {setCategoryActive({
        first: false,
        second: true,
        third: false
    })}
    const setThirdActive = () => {setCategoryActive({
        first: false,
        second: false,
        third: true
    })}




    function renderModal() {
        if (!modalState.show) {
            return null
        }
        switch(modalState.state) {
            case 'positive':
                return <ModalPositive close={closeModal} msgs={modalState.stateMsg}></ModalPositive>;
            case 'negative':
                return <ModalNegative close={closeModal} msgs={modalState.stateMsg}></ModalNegative>;
            default:
                return null;
        }
    }

    return (
        <main className="main">
            <div className="main__container container">
                <div className="tabs">
                    <div className="menu-wrapper">
                        <div className="menu">
                            <h3 className="head-reset menu__head">Категории расчетов</h3>
                            <ul className="list-reset menu__nav">
                                <li className="menu__item">
                                    <button onClick={setFirstActive}
                                            className={"btn menu__btn menu__btn_first" + (categoryActive.first ? " menu__btn_active" : "")}>
                                        Паровая конверсия природного газа
                                    </button>
                                </li>
                                <li className="menu__item">
                                    <button onClick={setSecondActive}
                                            className={"btn menu__btn" + (categoryActive.second ? " menu__btn_active" : "")}>
                                        Электролиз
                                    </button>
                                </li>
                                <li className="menu__item">
                                    <button onClick={setThirdActive}
                                            className={"btn menu__btn menu__btn_last" + (categoryActive.third ? " menu__btn_active" : "")}>
                                        Транспортировка и хранение
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-wrapper">
                            <button className="btn tabs__btn" onClick={openModal}>Рассчитать</button>
                        </div>
                    </div>
                    <div className="content">
                        {categoryActive.first ? <FirstNew ref={childRef}></FirstNew> : null}
                        {categoryActive.second ? <Second ref={childRef}></Second> : null}
                        {categoryActive.third ? <Third ref={childRef}></Third> : null}
                    </div>
                </div>

                {renderModal()}

                <div id="popup-root"/>

            </div>
        </main>
    );
};

export default Settlements;