import {Tooltip} from "react-tooltip";
import React from "react";

const ThirdListTooltips = () => {
    const tooltips = [
        "",
        "Газообразный водород обычно транспортируется в стальных цилиндрических контейнерах емкостью 20 и 24 м\u00B3 под давлением до 20 МПа",
        "При транспортировке сжиженного водорода применяются автоцистерны 25 м\u00B3, при этом неизбежны потери в связи с непрерывным испарением водорода и технологическими операциями. Дополнительно имеются требования по постоянному поддержанию температуры.",
        "Системы хранения и транспортировки водорода в связанной формы представлено в виде различного типа носителей (гидриды металлов, аланаты, борогидриды, амиды), а также в мульти капиллярных структурах",
        ]
    const getTooltips = () => {
        const rows = [];
        for (let i = 1; i < 4; i++) {
            rows.push(
                <>
                    <div className={"schema-div transportirovka__div_" + i} id={"tooltip_" + i} key={"tooltip_anchor_"+i}></div>
                    <Tooltip
                        key={"tooltip_"+i}
                        // Don't forget the `#`!
                        anchorSelect={"#tooltip_" + i}
                        content={tooltips[i]}
                        style={{maxWidth: "50%"}}
                    />
                </>
            )
        }
        return rows;
    }
    return getTooltips()
}

export default ThirdListTooltips;