import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/UI/navbar/Navbar";
import './css/normalize.css'
import './css/style.css'
import './css/media.css'
import First from "./components/UI/first/First";
import Index from "./components/page/Index";
import Settlements from "./components/page/Settlements";

function App() {
  return (
      <BrowserRouter>
          <Navbar></Navbar>
          <Routes>
              <Route path="/" element={<Index/>}></Route>
              <Route path="/settlements" element={<Settlements/>}></Route>
              <Route path="/first" element={<First/>}></Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
