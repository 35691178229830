import React from "react";
import IconX from './IconX.svg';
import IconExclamationCircle from './IconExclamationCircle.svg';

const ModalNegative = ({close, msgs}) => {
    const getPs = () => {
        const rows = [];
        for (let i in msgs) {
            rows.push(<p className="paragraph-reset modal__descr">
                {msgs[i]}
            </p>);
        }
        return rows
    }
    return (
        <div className="calculation__negative modal">
            <div className="modal__head">
                <img src={IconExclamationCircle} alt="!" className="modal__icon"/>
                <p className="paragraph-reset modal__title">
                    Расчет не выполнен
                </p>
            </div>
            {getPs()}
            <a href="#" onClick={close} className="modal__close">
                <img src={IconX} alt="x" className="modal__close_icon"/>
            </a>
        </div>
    );
};

export default ModalNegative;