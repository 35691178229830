import {Tooltip} from "react-tooltip";
import React, {useState} from "react";

const SecondInputs = ({ins, setIns, out, setOut}) => {
    let arr = new Array(7)
    let [arrColor, setArrColor] = useState(Array(8).fill("#1A1A19"))
    const validateField = (i, tr) => {
        if (Number(tr) < 0 && tr !== "") {
            arrColor[i] = "#FD002E"
        } else {
            arrColor[i] = "#1A1A19"
        }
        setArrColor(arrColor)
    }
    return (
        <>
            <input value={arr[0]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[0] = e.target.value
                validateField(0, arr[0])
                newIns.in1.values["0"] = arr[0]
                setIns(newIns)
            }} className="schema-input elektroliz__input_1" type="number" id="input_1" style={{color: arrColor[0]}}/>
            <input value={arr[1]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[1] = e.target.value
                validateField(1, arr[1])
                newIns.in2.values["0"] = arr[1]
                setIns(newIns)
            }} className="schema-input elektroliz__input_2" type="number" name="" id="input_2" style={{color: arrColor[1]}}/>
            <input value={arr[2]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[2] = e.target.value
                validateField(2, arr[2])
                newIns.in3.values["0"] = arr[2]
                setIns(newIns)
            }} className="schema-input elektroliz__input_3" type="number" name="" id="input_3" style={{color: arrColor[2]}}/>
            <input value={arr[3]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[3] = e.target.value
                validateField(3, arr[3])
                newIns.in3.values["0"] = arr[3]
                setIns(newIns)
            }} className="schema-input elektroliz__input_4" type="number" name="" id="input_4" style={{color: arrColor[3]}}/>
            <input value={arr[4]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[4] = e.target.value
                validateField(4, arr[4])
                newIns.in3.values["0"] = arr[4]
                setIns(newIns)
            }} className="schema-input elektroliz__input_5" type="number" name="" id="input_5" style={{color: arrColor[4]}}/>
            <input value={arr[5]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[5] = e.target.value
                validateField(5, arr[5])
                newIns.in3.values["0"] = arr[5]
                setIns(newIns)
            }} className="schema-input elektroliz__input_6" type="number" name="" id="input_6" style={{color: arrColor[5]}}/>
            <input value={arr[6]} onChange={e => {
                let newIns = Object.assign({}, ins)
                arr[6] = e.target.value
                validateField(6, arr[6])
                newIns.in3.values["0"] = arr[6]
                setIns(newIns)
            }} className="schema-input elektroliz__input_7" type="number" name="" id="input_7" style={{color: arrColor[6]}}/>
            <input value={out} onChange={e => {
                validateField(7, e.target.value)
                setOut(e.target.value)
            }} className="schema-input elektroliz__input_8" type="number" name="" id="input_out" style={{color: arrColor[7]}}/>
        </>

    );
}

export default SecondInputs;